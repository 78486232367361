<template>
  <div class="clearfix">
    <a-upload
      :list-type="type == 'image' ? 'picture-card' : 'picture'"
      :show-upload-list="type == 'image' ? true : type == 'video' ? false : true"
      :before-upload="beforeUpload"
      :customRequest="handleChange"
      :disabled="disabled"
      @preview="handlePreview"
      @change="changeImg"
      :multiple="count === 1 ? false : true"
      :default-file-list="defaultList"
      :file-list="fileList"
    >
      <div v-if="fileList.length < count && !disabled">
        <span v-if="type == 'image'">
          <a-icon :type="loading ? 'loading' : 'plus'" />
          <div class="ant-upload-text">上传</div>
        </span>
        <!-- 文件类型  -->
        <div v-if="type == 'file'">
          <a-button> <a-icon type="upload" /> 上传 </a-button>
        </div>
        <div v-if="type == 'video'">
          <div class="imgbox" v-for="(item, index) in fileList" :key="index" @click="handlePreviewVideo(item)">
            <div class="videomask">
              <a-icon class="icon" type="play-circle" />
            </div>
            <img
              :src="item.path + '?x-oss-process=image/resize,m_fill,h_422,w_350'"
              alt=""
              class="response"
              style="width: 100px"
            />
          </div>
        </div>
      </div>
    </a-upload>
    <a-progress :percent="percent" v-show="isPercent" />
    <span v-if="type == 'file'" style="color: red">建议上传文件大小 200MB以内</span>
    <span v-if="type == 'image'" style="color: red">建议尺寸：{{imgSize==""?'204x215':imgSize }}</span>
    <span v-if="type == 'video'" style="color: red">建议上传视频大小 10MB</span>

    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <video
        v-show="isVideo"
        style="width: 100%"
        :src="previewImage"
        ref="myVideo"
        :poster="previewImage + '?x-oss-process=video/snapshot,t_7000,f_jpg,w_800,m_fast'"
        :controls="true"
      ></video>
      <img v-show="!isVideo" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

// import storage from 'store'
// import { ACCESS_TOKEN } from '@/store/mutation-types'
import UploadFileToOSS from '@/utils/upload-file-to-oss.js'
export default {
  name: 'ImageUpload',

  props: {
    value: {
      type: String,
      default: '',
    },
    // 上传的文件类型 image图片 file文件
    type: {
      type: String,
      default: 'image',
    },
    count: {
      type: Number,
      default: 1,
    },
    imgSize:{
      type: String,
      default: '',
    },
    defaultList: {
      type: Array,
      default() {
        return []
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    defaultList: {
      handler(val) {
        this.fileList = val
   
        this.$forceUpdate()
        // switch(this.$route.path){
        //   case "/health/deviceVersion":{
        //     this.imgSize = "660*660"
        //     break;
        //   }
        //   case "/health/health":{
        //     this.imgSize = "200*200"
        //     break;
        //   }
        //   case "/health/healthHome":{
        //     this.imgSize = "160*160"
        //     break;
        //   }
 
        //   case "/health/dial":{
        //     this.imgSize = "188*188"
        //     break;
        //   }
        //    case "/mall/CreateForms":{
        //      this.imgSize = "339*339"
        //      break;
        //    }
        //   case "/cookbook/exercise":{
        //     this.imgSize = "690*454"
        //     break;
        //   }
        //    case "/cookbook/food_manage":{
        //      this.imgSize = "160*160"
        //      break;
        //    }
        //    case "/challenge/challengeInfo":{
        //      this.imgSize = "310*200"
        //      break;
        //    }
        //   case "/shop/shop":{
        //     this.imgSize = "310*200"
        //     break;
        //   }

          
        //   default :{
        //     "204x215px"
        //   }
        // }
      },
      deep: true,
      immediate: true,
    },
  },
  components: {},
  created() {
    // this.$nextTick(() => {
    //   setTimeout(()=>{
    //     this.fileList = this.defaultList
    //   },1000)
    // })

    console.log(this.imgSize,"this.imgSize")
  },

  data() {
    return {
      // imgSize:"",
      percent: 0,
      isPercent: false,
      loading: false,
      open: false,
      isVideo: false,
      // uploadImgUrl: process.env.VUE_APP_BASE_API + '/common/upload',
      // headers: {
      //   Authorization: 'Bearer ' + storage.get(ACCESS_TOKEN)
      // },
      previewVisible: false,
      previewImage: '',
      fileList: [],
      progress: {
        strokeColor: {
          '0%': '#108ee9',
          '100%': '#87d068',
        },
        strokeWidth: 3,
        // format: percent => `${parseFloat(percent.toFixed(2))}%`,
        class: 'test',
      },
    }
  },
  mounted() {},
  methods: {
    beforeUpload(file) {
      // 文件类型(file.type)、大小限制(file.size)
      const isLt2M = file.size / 1024 / 1024 < 400
      if (!isLt2M) {
        this.$message.error('大小限制 400MB!')
      }
      return isLt2M
    },
    /**自定义上传事件 */
    async handleChange(info) {
      if (this.type === 'image') {
        const allowedFormats = ['image/jpeg', 'image/png']
        if (!allowedFormats.includes(info.file.type)) {
          this.$message.error('只允许上传JPEG或PNG格式的文件！')
          return
        }
      }
      if (this.type === 'video') {
        const allowedFormats = ['video/mp4', 'video/webm', 'video/quicktime']
        if (!allowedFormats.includes(info.file.type)) {
          this.$message.error('只允许上传MP4、WebM或QuickTime格式的视频文件！')
          return
        }
      }
   
      console.log(info,"customRequest()")
      this.isPercent = true
      this.percent =0
      const intervalId = setInterval(() => {
          this.percent = this.percent + 15
          if(this.percent>88){
              clearInterval(intervalId)
          }
        }, 500)
      new UploadFileToOSS([info.file], {
        finally: (res) => {
          console.log(res, 'resfilnlly')
          this.percent = 100
          setTimeout(() => {
            this.isPercent = false
            this.percent = 0
          }, 500)
          if (this.fileList.length < this.count) {
            this.fileList = this.fileList.concat(res)
            this.$emit('input', this.fileList)
          }
        },
      })
    },

    //设置上传进度值
    // setUploadProcess(percent, file, index) {
    //   Object.assign(file, {percent})
    //   this.fileList.splice(index, 1, file)
    // },

    /**删除图片 */
    changeImg(info) {
      console.log(info, 'change()')
      
   
      if (info.file.status === 'removed') {
        this.isPercent = false
        this.percent = 0
        if (this.count === 1) {
          this.fileList = []
          this.$emit('input', this.fileList)
        } else {
          let index = this.fileList.findIndex((ele) => ele.uid === info.file.uid)
          this.fileList.splice(index, 1)
          this.$emit('input', this.fileList)
        }
        this.$forceUpdate()
      }else{
       
   
      }
    },
    /**图片预览 */
    async handlePreview(file) {
      this.isVideo = false
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview

      if(this.containsChar(this.previewImage,'.jpg')||this.containsChar(this.previewImage,'.jpeg')||this.containsChar(this.previewImage,'.png')){
        this.previewVisible = true
      }
     
    },
    containsChar(str, charToFind) {
  return str.indexOf(charToFind) !== -1;
},
    handlePreviewVideo(info) {
      this.isVideo = true
      this.previewImage = info.url
      this.$nextTick(() => {
        this.$refs.myVideo.play()
      })
    },
    handleCancel() {
      this.previewVisible = false
    },
  },
}
</script>
<style lang="less" scoped>
// img {
//   width: 128px;
//   height: 128px;
// }

/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.imgbox {
  width: 100px;
  height: 100px;
  overflow: hidden;
  background-color: #f7f7f7;
  position: relative;
  cursor: pointer;
}

.videomask {
  position: absolute;
  z-index: 10;
  width: 100px;
  height: 100px;
  line-height: 100px;
  background: rgba(0, 0, 0, 0.3);
  text-align: center;

  .icon {
    display: inline-block;
    font-size: 26px !important;
    color: #fff !important;
  }
}
</style>
