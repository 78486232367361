export default {
    'app.setting.pagestyle': '整体风格设置',
    'app.setting.pagestyle.light': '亮色菜单风格',
    'app.setting.pagestyle.dark': '暗色菜单风格',
    'app.setting.pagestyle.realdark': '暗黑模式',
    'app.setting.themecolor': '主题色',
    'app.setting.navigationmode': '导航模式',
    'app.setting.content-width': '内容区域宽度',
    'app.setting.fixedheader': '固定 Header',
    'app.setting.fixedsidebar': '固定侧边栏',
    'app.setting.multitab': '显示页签',
    'app.setting.sidemenu': '侧边菜单布局',
    'app.setting.topmenu': '顶部菜单布局',
    'app.setting.content-width.fixed': 'Fixed',
    'app.setting.content-width.fluid': 'Fluid',
    'app.setting.othersettings': '其他设置',
    'app.setting.weakmode': '色弱模式',
    'app.setting.copy': '拷贝设置',
    'app.setting.loading': '加载主题中',
    'app.setting.copyinfo': '拷贝设置成功 src/config/defaultSettings.js',
    'app.setting.production.hint': '配置栏只在开发环境用于预览，生产环境不会展现，请拷贝后手动修改配置文件',
    'app.setting.themecolor.daybreak': '拂晓蓝',
    'app.setting.themecolor.dust': '薄暮',
    'app.setting.themecolor.volcano': '火山',
    'app.setting.themecolor.sunset': '日暮',
    'app.setting.themecolor.cyan': '明青',
    'app.setting.themecolor.green': '极光绿',
    'app.setting.themecolor.geekblue': '极客蓝',
    'app.setting.themecolor.purple': '酱紫',

    '通用': {
        '查询': '查询',
        '重置': '重置',
        '新增': '新增',
        '修改': '修改',
        '删除': '删除',
        '取消': '取消',
        '确认': '确认',
        '保存': '保存',
        '导出': "导出",
        '导入':"导入",
        '查看服务': '查看服务',
        '审核':'审核',
        '展开':'展开',
        '收起':'收起',
        '更多':'更多',
        '刷新缓存':'刷新缓存',
        '详情':'详情',
        '解锁':'解锁',
        '清空':'清空'

    },

    '服务管理': {
        '服务内容管理': {
            '名称': '名称',
            '请输入名称': '请输入名称',
            'id': 'id',
            '图标': '图标',
            '描述': '描述',
            '是否启用': '是否启用',
            '添加时间': '添加时间',
            '操作': '操作',
            '确认删除所选中数据': '确认删除所选中数据?',
            '当前选中编号为': '当前选中编号为',
            '图片': '图片'
        },
        '轮播图管理': {
            '标题': '标题',
            '位置': '位置',
            '请输入标题': '请输入标题',
            '请选择位置': '请选择位置',
            '首页': '首页',
            '服务': '服务',
            "类型": '类型',
            '跳转地址': '跳转地址',
            '请输入跳转地址': '请输入跳转地址'
        },
        '后巷屋管理': {
            "封面": '封面',
            '视频地址': '视频地址',
            '预估价格': '预估价格',
            '标题': '标题',
            '视频': '视频',
            "图片上传": '图片上传',
            "定金": '定金',
            "内容": "内容",
            "备注": '备注',
            '请输入封面': '请输入封面',
            "请输入定金": '请输入定金',
            '请输入预估价格': "请输入预估价格",
            '请输入标题': '请输入标题',
            '请输入备注': '请输入备注'

        },
        '用户咨询管理': {
            '类型': '类型',
            '贷款咨询': '贷款咨询',
            '多项目咨询': '多项目咨询',
            '联系平台': '联系平台'
        },
        "新闻管理": {
            '标题': '标题',
            '封面': '封面',
            '新闻内容': '新闻内容',
            '请输入标题': '请输入标题'
        },
        "后巷屋订单管理": {
            "后巷屋标题": '后巷屋标题',
            '请输入后巷屋标题': '请输入后巷屋标题',
            "上传报告": '上传报告',
            "查看服务": '查看服务',
            "服务状态": '服务状态',
            "服务名称": '服务名称',
            "订单服务详情": '订单服务详情',
            "请输入服务名称": '请输入服务名称',
            "审核": '审核',
            "支付状态": '支付状态',
            '请选择支付状态': '请选择支付状态',
            '线下支付截图': '线下支付截图'
        },
        "服务订单管理": {
            "项目名称": '项目名称',
            '请输入后巷屋标题': '请输入后巷屋标题',
            "上传报告": '上传报告',
            "查看服务": '查看服务',
            "服务状态": '服务状态',
            "服务名称": '服务名称',
            "方案名称": '方案名称',
            '请输入方案名称': '请输入方案名称',
            "订单服务详情": '订单服务详情',
            "请输入服务名称": '请输入服务名称',
            '服务订单id': '服务订单id',
            '请输入服务订单id': '请输入服务订单id',
            "审核": '审核',
            "支付状态": '支付状态',
            '请选择支付状态': '请选择支付状态',
            '线下支付截图': '线下支付截图',
            '订单id': '订单id',
            "服务者id": '服务者id',
            '进度百分比': '进度百分比',
            '价格': '价格',
            '方案资料': '方案资料',
            '支付时间': '支付时间',
            '支付金额': '支付金额',
            '定金抵扣金额': '定金抵扣金额',
            '备注': '备注',
            '请输入项目名称': '请输入项目名称',
            '详情': '详情',
            '请输入进度百分比': '请输入进度百分比',
            '请输入价格': '请输入价格',
            '请输入内容': '请输入内容',
            '服务订单报告': '服务订单报告',
            '服务订单报告上传': '服务订单报告上传'


        },
        '服务订单请求管理': {
            '状态': '状态',
            '查看方案': '查看方案',
            "服务者id": '服务者id',
            '用户项目id': '用户项目id',
            '请输入服务者id': '请输入服务者id',
            '请输入用户项目id': '请输入用户项目id',
            '订单id': '订单id',
            '请输入订单id': '请输入订单id',
            '服务状态': '服务状态',
            '服务签约定金': '服务签约定金',
            '报价方案': '报价方案',
            '请输入内容': '请输入内容',
            '备注': "备注",
            '请选择服务状态': '请选择服务状态',
            '请输入服务签约定金':'请输入服务签约定金',
            '方案详情':'方案详情',
            '方案列表':'方案列表'

        },
        '用户认证管理':{
               '姓名':'姓名' ,
               '请输入姓名':'请输入姓名',
               '状态':'状态',
               '请选择状态':'请选择状态',
               '审核':'审核',
               '审核状态':'审核状态',
               '电话':'电话',
               '邮箱':'邮箱',
               '项目类别':'项目类别',
               '通过':'通过',
               '驳回':'驳回',
               '驳回原因':'驳回原因',
               '请输入驳回原因':'请输入驳回原因'
        },
        '用户授权管理':{
               '项目地址':'项目地址',
               '业主姓名':'业主姓名',
               '审核状态':'审核状态',
               '请输入项目地址':'请输入项目地址',
               '请输入业主姓名':'请输入业主姓名',
               '土地面积':'土地面积',
               '请输入土地面积':'请输入土地面积',
               '开发经验':"开发经验",
               '请输入开发经验':'请输入开发经验',
               '身份':'身份',
               '服务内容':'服务内容',
               '请输入服务内容':'请输入服务内容',
               '签名':'签名',
               '审核详情':'审核详情',
               '通过':'通过',
               '驳回':'驳回',
               '驳回原因':'驳回原因',
               '请输入驳回原因':'请输入驳回原因'


        },
       
    },
    '用户信息':{
        '手机号':'手机号' ,
        '邮箱':'邮箱',
        '性别':'性别',
        '邀请码':'邀请码',
        '是否推送':'是否推送',
        '用户状态':'用户状态',
        '请输入手机号':'请输入手机号',
        '请输入邮箱':"请输入邮箱",
        '请输入昵称':'请输入昵称',
        '请输入邀请码':'请输入邀请码',
     },
     '用户注销':{
          '联系电话':'联系电话',
          '请输入联系电话':'请输入联系电话',
          '审核状态':'审核状态',
          '注销原因':'注销原因',
          '请输入注销原因':'请输入注销原因',
          '审核失败原因':"审核失败原因",
          '请输入审核失败原因':'请输入审核失败原因',
          '审核人':'审核人',
          '请输入审核人':'请输入审核人',
          '联系电话':'联系电话',
          '请输入联系电话':'请输入联系电话',
          '注销原因':'注销原因',
          '请选择审核状态':'请选择审核状态',
          '备注':"备注" ,
          '请输入备注':'请输入备注'
     },
     '用户管理':{
           "请输入搜索内容":'请输入搜索内容',
           '用户名称':'用户名称',
           "请输入用户名称":'请输入用户名称',
           '手机号':"手机号",
           "请输入手机号":'请输入手机号',
           "创建时间":'创建时间',
           '新增用户':"新增用户",
           '修改用户':'修改用户',
           '用户昵称':"用户昵称",
           "请输入用户昵称":"请输入用户昵称",
           '部门':"部门",
           "请选择用户部门":"请选择用户部门",
           "邮箱":'邮箱',
           '请输入邮箱':'请输入邮箱',
           '密码':"密码",
           '请输入密码':'请输入密码',
           '性别':'性别',
           '男':'男',
           '女':'女',
           "未知":'未知',
           '状态':"状态",
           "请选择状态":'请选择状态',
           '正常':'正常',
           '停用':'停用',
           '岗位':'岗位',
           '请选择岗位':"请选择岗位",
           '角色':'角色',
           '请选择角色':'请选择角色',
           '备注':'备注',
           '请输入备注':'请输入备注',
           '重置密码':'重置密码',
           '分配角色':"分配角色",
           '新密码':'新密码',
           '请输入新密码':'请输入新密码',
           '确认密码':'确认密码',
           '请确认密码':'请确认密码',
           '的数据':'的数据'
     },
     '角色管理':{
            '角色名称':'角色名称',
            '请输入角色名称':'请输入角色名称',
            '权限字符':'权限字符',
            '请输入权限字符':'请输入权限字符' ,
            '状态':'状态',
            '请选择状态':'请选择状态',
            '创建时间':'创建时间',
            '脱敏权限':'脱敏权限',
            '数据权限':'数据权限',
            '分配用户':'分配用户',
            '添加角色':'添加角色',
            '修改角色':'修改角色',
            '角色名称':'角色名称',
            "请输入角色名称":'请输入角色名称',
            '权限字符':'权限字符',
            '排序':'排序',
            '请输入排序':'请输入排序',
            '备注':'备注',
            '请输入备注':'请输入备注',
            '菜单权限':'菜单权限',
            '列名':'列名',
            '请输入列名':'请输入列名',
            '列名描述':'列名描述',
            '请输入列名描述':'请输入列名描述'
     },
     '系统管理':{
           '菜单管理':{
                '菜单名称':'菜单名称',
                '请输入菜单名称':'请输入菜单名称',
                "状态":'状态',
                '请选择状态':'请选择状态',
                '添加菜单':'添加菜单',
                '修改菜单':'修改菜单',
                '上级菜单':'上级菜单',
                '菜单类型':'菜单类型',
                '图标':'图标',
                '排序':'排序',
                '请输入排序':'请输入排序',
                '是否外链':'是否外链',
                '路由地址':'路由地址',
                '请输入路由地址':'请输入路由地址',
                '是否显示':'是否显示',
                '状态':'状态',
                '目录':'目录',
                '菜单':'菜单',
                '按钮':"按钮",
                '组件路径':"组件路径",
                '请输入组件路径':'请输入组件路径',
                '是否缓存':'是否缓存'
           } ,
           '脱敏管理':{
                '列名':'列名',
                '请输入列名':'请输入列名',
                '列名描述':'列名描述',
                '请输入列名描述':'请输入列名描述',
                '修改脱敏数据':'修改脱敏数据',
                '脱敏方向':'脱敏方向',
                '从左向右':'从左向右',
                '从右向左':'从右向左',
                '起始位置':'起始位置',
                '请输入起始位置':'请输入起始位置',
                '脱敏字符数':'脱敏字符数',
                '请输入脱敏字符数':'请输入脱敏字符数',
                '是否开启':'是否开启',
                '正常':'正常',
                '停用':'停用',
                '备注':'备注',
                '请输入备注':'请输入备注'
           } ,
           '部门管理':{
                 '部门名称':'部门名称',
                 '请输入部门名称':'请输入部门名称',
                 '状态':'状态',
                 '请选择状态':'请选择状态',
                 '启用':'启用',
                 '禁用':'禁用',
                 '添加部门':'添加部门',
                 '修改部门':'修改部门',
                 '上级部门':'上级部门',
                 '请选择上级部门':'请选择上级部门',
               
                 '排序':'排序',
                 '请输入排序':'请输入排序',
                 '负责人':'负责人',
                 '请输入负责人':'请输入负责人',
                 '联系电话':'联系电话',
                 '请输入联系电话':'请输入联系电话',
                 '邮箱':'邮箱',
                 '请输入邮箱':'请输入邮箱',
                 '状态':'状态'
                 
           },
           '岗位管理':{
                '岗位编码':'岗位编码',
                '请输入岗位编码':'请输入岗位编码',
                '岗位名称':'请输入岗位名称',
                '添加岗位':'添加岗位',
                '修改岗位':'修改岗位',
                '岗位名称':'岗位名称',
                '请输入岗位名称':'请输入岗位名称',
                '岗位编码':'岗位编码',
                '请输入岗位编码':'请输入岗位编码',
                '显示顺序':'显示顺序',
                '请输入顺序':'请输入顺序',
                '状态':'状态',
                '请选择状态':'请选择状态',
                '备注':'备注',
                '请输入备注':'请输入备注'
           } ,
           '字典管理':{
               '字典名称':"字典名称",
               '请输入字典名称':'请输入字典名称',
               '字典类型':'字典类型',
               '请输入字典类型':'请输入字典类型',
               '状态':'状态',
               '请选择状态':'请选择状态',
               '添加字典类型':'添加字典类型',
               '修改字典类型':'修改字典类型',
               '备注':'备注',
               '请输入备注':'请输入备注',
               '刷新缓存':'刷新缓存'
           },
           '参数设置':{
                 '参数名称':'参数名称',
                 '请输入参数名称':'请输入参数名称',
                 '参数键名':'参数键名',
                 '请输入参数键名':'请输入参数键名',
                 '创建时间':'创建时间',
                 '系统内置':'系统内置',
                 '请选择':'请选择',
                 '参数分组':'参数分组',
                 '请输入参数分组':'请输入参数分组',
                 '参数类型':'参数类型',
                 '参数键值':'参数键值',
                 '请输入参数键值':'请输入参数键值',
                 '系统内置':'系统内置',
                 '备注':'备注',
                 '请输入备注':'请输入备注',
                 '添加参数':'添加参数',
                 '修改参数':'修改参数'
           },
           '通知公告':{
                '公告标题':'公告标题',
                '请输入公告标题':'请输入公告标题',
                '操作人员':'操作人员',
                '请输入操作人员':'请输入操作人员',
                '公告类型':'公告类型',
                '请选择公告类型':'请选择公告类型'
           },
           '日志管理':{
                 '操作日志':{
                        '系统模块':'系统模块' ,
                        '请输入系统模块':'请输入系统模块',
                        '操作人员':'操作人员',
                        '请输入操作人员':'请输入操作人员',
                        '类型':'类型',
                        '操作类型':'操作类型',
                        '状态':'状态',
                        '操作状态':'操作状态',
                        '操作时间':'操作时间',
                        '操作信息':'操作信息',
                        '操作模块':'操作模块',
                        '登录信息':'登录信息',
                        '请求地址':'请求地址',
                        '操作方法':'操作方法',
                        '请求方式':'请求方式',
                        '接口信息':'接口信息',
                        '请求参数':"请求参数",
                        '返回参数':'返回参数',
                        '操作状态':'操作状态',
                        '操作日期':'操作日期',
                        '异常信息':'异常信息'
                 },
                 '登录日志':{
                       '登录地址':'登录地址',
                       '请输入登录地址':'请输入登录地址',
                       '使用状态':'使用状态',
                       '请选择状态':'请选择状态',
                       '登录时间':'登录时间',
                       '登录名称':'登录名称',
                       '请输入登录名称':'请输入登录名称'

                 }
           },
           'DIY项目管理':{
                '项目名称':'项目名称',
                '请输入项目名称':'请输入项目名称',
                '联系人姓名':'联系人姓名',
                '请输入联系人姓名':'请输入联系人姓名',
                '添加DIY项目信息':'添加DIY项目信息',
                '修改DIY项目信息':'修改DIY项目信息',
                '项目名称':'项目名称',
                '请输入项目名称':'请输入项目名称',
                'DIY模块项目ID':'DIY模块项目ID',
                '联系人电话':'联系人电话',
                '请输入联系人电话':'请输入联系人电话',
                '数据库IP':'数据库IP',
                '请输入数据库IP':'请输入数据库IP',
                '数据库端口':'数据库端口',
                '请输入数据库端口':'请输入数据库端口',
                '数据库名称':'数据库名称',
                '请输入数据库名称':'请输入数据库名称',
                '数据库用户名':'数据库用户名',
                '请输入数据库用户名':'请输入数据库用户名',
                '数据库密码':'数据库密码',
                '请输入数据库密码':"请输入数据库密码"

           }
     },
     '经纪人/服务者':{
        '经纪人':{
              '代理人姓名':'代理人姓名',
             
              '代理人所在公司':'代理人所在公司',
             
              '代理人电话':'代理人电话',
             
              '业主姓名':'业主姓名',
             
              '电话号码':'电话号码',
             
              '头像':'头像',
              '所在公司年份':'所在公司年份',
              '代理人邮件':'代理人邮件',
              '推荐项目地址':'推荐项目地址',
              '电话号码':'电话号码',
              '城市':'城市',
              '邮政编码':'邮政编码',
              '邀请码':'邀请码',
              '审核状态':'审核状态',
              '未审核':'未审核',
              '已通过':'已通过',
              '可以试看':'可以试看',
              '审核驳回的情况下的拒绝原因':'审核驳回的情况下的拒绝原因',
              '审核':'审核',
              '通过':'通过',
              '拒绝':'拒绝',
              '请输入代理人姓名':'请输入代理人姓名',
              '请输入代理人所在公司':'请输入代理人所在公司',
              '请输入代理人电话':'请输入代理人电话',
              '请输入业主姓名':'请输入业主姓名',
              '请输入电话号码':'请输入电话号码',

        }  ,
        '服务者':{
             '总经理姓名':'总经理姓名',
             '请输入总经理姓名':'请输入总经理姓名',
             '公司名称':'公司名称',
             '请输入公司名称':'请输入公司名称',
             '电话号码':'电话号码',
             '请输入电话号码':'请输入电话号码',
             '详情':'详情',
             '公司地址':'公司地址',
             '电子邮件':'电子邮件',
             '专业身份':'专业身份',
             '其他':'其他',
             '总工龄':"总工龄",
             '公司人数':'公司人数',
             '公司在银行信用额度':'公司在银行信用额度',
             '是否推荐':'是否推荐',
             '是':'是',
             '否':'否',
             '是否开启服务':'是否开启服务',
             '服务金额':'服务金额',
             '评分':'评分',
             '擅长':'擅长',
             '详细介绍':'详细介绍',
             '备注':'备注',
             '审核':'审核',
             '通过':'通过',
             '拒绝':'拒绝',
            '审核拒绝原因':'审核拒绝原因',

        },
        '身份设置':{
              '身份':'身份',
              '请输入身份':'请输入身份',
              '上级身份,顶级不选':'上级身份,顶级不选',
              '请输入':'请输入',
              '图片':'图片',
              '备注':'备注',
              '请输入备注':'请输入备注'
        }
  }
    
}
