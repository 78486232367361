export default {
    'app.setting.pagestyle': 'Page style setting',
    'app.setting.pagestyle.light': 'Light style',
    'app.setting.pagestyle.dark': 'Dark style',
    'app.setting.pagestyle.realdark': 'RealDark style',
    'app.setting.themecolor': 'Theme Color',
    'app.setting.navigationmode': 'Navigation Mode',
    'app.setting.content-width': 'Content Width',
    'app.setting.fixedheader': 'Fixed Header',
    'app.setting.fixedsidebar': 'Fixed Sidebar',
    'app.setting.multitab': 'View multitab',
    'app.setting.sidemenu': 'Side Menu Layout',
    'app.setting.topmenu': 'Top Menu Layout',
    'app.setting.content-width.fixed': 'Fixed',
    'app.setting.content-width.fluid': 'Fluid',
    'app.setting.othersettings': 'Other Settings',
    'app.setting.weakmode': 'Weak Mode',
    'app.setting.copy': 'Copy Setting',
    'app.setting.loading': 'Loading theme',
    'app.setting.copyinfo': 'copy success，please replace defaultSettings in src/config/defaultSettings.js',
    'app.setting.production.hint': 'Setting panel shows in development environment only, please manually modify',
    'app.setting.themecolor.daybreak': 'Daybreak Blue',
    'app.setting.themecolor.dust': 'Dust Red',
    'app.setting.themecolor.volcano': 'Volcano',
    'app.setting.themecolor.sunset': 'Sunset Orange',
    'app.setting.themecolor.cyan': 'Cyan',
    'app.setting.themecolor.green': 'Polar Green',
    'app.setting.themecolor.geekblue': 'Geek Blue',
    'app.setting.themecolor.purple': 'Golden Purple',

    '通用': {
        '查询': 'search',
        '重置': 'reset',
        '新增': 'Add',
        '修改': 'Edit',
        '删除': 'delete',
        '取消': 'Cancel',
        '确认': 'Enter',
        '保存': 'Save',
        '导出': "Export",
        "导入": 'to lead',
        '查看服务': 'View service',
        '审核': 'Audit',
        '展开': 'unfold',
        '收起': 'pack up',
        '更多': 'more',
        '刷新缓存':'Refresh cache',
        '详情':'details',
        '解锁':'unlock',
        '清空':'empty'
    },

    '服务管理': {
        '服务内容管理': {
            '名称': 'name',
            '请输入名称': 'Please enter name',
            'id': 'ID',
            '图标': 'icon',
            '描述': 'Description',
            '是否启用': 'Enable or not',
            '添加时间': 'add time',
            '操作': 'operation',
            '确认删除所选中数据': 'Confirm to delete the selected data?',
            '当前选中编号为': 'The current selected number is ',
            '图片': 'image'
        },
        '轮播图管理': {
            '标题': 'title',
            '位置': 'location',
            '请输入标题': 'Please enter the title',
            '请选择位置': 'Please select a location',
            '首页': 'Home',
            '服务': 'Serve',
            "类型": 'Type',
            '跳转地址': 'Jump address',
            '请输入跳转地址': 'Please enter the forward address'
        },
        '后巷屋管理': {
            "封面": 'Cover',
            '视频地址': 'Video address',
            '预估价格': 'estimated price',
            '标题': 'title',
            '视频': 'Video',
            "图片上传": 'image upload',
            "定金": 'deposit',
            "内容": "Content",
            "备注": 'remark',

            "请输入定金": 'Please enter the deposit',
            '请输入预估价格': "Please enter an estimated price",
            '请输入标题': 'Please enter the title',
            '请输入备注': 'Please enter remarks'

        },
        '用户咨询管理': {
            '类型': 'Type',
            '贷款咨询': 'Loan consultation',
            '多项目咨询': 'Multi-project consulting',
            '联系平台': 'Contact platform'
        },
        "新闻管理": {
            '标题': 'title',
            '封面': 'Cover',
            '新闻内容': 'news content',
            '请输入标题': 'Please enter the title'

        },
        "后巷屋订单管理": {
            "后巷屋标题": 'Back alley house title',
            '请输入后巷屋标题': 'Please enter Back alley house title',
            "上传报告": 'Upload report',
            "查看服务": 'View service',
            "服务状态": 'service state',
            "服务名称": 'service name',
            "订单服务详情": 'Order service Details',
            "请输入服务名称": 'Please enter a service name',
            "审核": 'audit',
            "支付状态": 'state of payment',
            '请选择支付状态': 'Please select payment status',
            '线下支付截图': 'Screenshot of offline payment'
        },
        "服务订单管理": {
            "项目名称": 'project name',
            '请输入后巷屋标题': 'Please enter Back alley house title',
            "上传报告": 'Upload report',
            "查看服务": 'View service',
            "服务状态": 'service state',
            "服务名称": 'service name',
            "方案名称": 'scenario name',
            '请输入方案名称': 'Please enter a scheme name',
            '请输入服务订单id': 'Please enter the service order id',
            "订单服务详情": 'Order service Details',
            "请输入服务名称": 'Please enter a service name',
            "审核": 'audit',
            "支付状态": 'state of payment',
            '请选择支付状态': 'Please select payment status',
            '线下支付截图': 'Screenshot of offline payment',
            '订单id': 'order id',
            "服务者id": 'server id',
            '服务订单id': 'Service order id',
            '进度百分比': 'progress percentage',
            '价格': 'price',
            '方案资料': 'Scheme data',
            '支付时间': 'pay time',
            '支付金额': 'payment amount',
            '定金抵扣金额': 'Amount of deduction for deposit',
            '备注': 'remark',
            '请输入项目名称': 'Please enter a project name',
            '详情': 'details',
            '请输入进度百分比': 'Please enter the percentage of progress',
            '请输入价格': 'Please enter price',
            '请输入内容': 'Please enter content',
            '服务订单报告': 'Service order report',
            '服务订单报告上传': 'Service order report uploaded'

        },
        '服务订单请求管理': {
            '状态': 'status',
            '查看方案': 'View solution',
            "服务者id": 'server id',
            '用户项目id': 'User item id',
            '请输入服务者id': 'Please enter the server id',
            '请输入用户项目id': 'Please enter the user project id',
            '订单id': 'order id',
            '请输入订单id': 'Please enter the order id',
            '服务状态': 'Service status',
            '服务签约定金': 'Service contract deposit',
            '报价方案': 'Quotation scheme',
            '请输入内容': 'Please enter content',
            '备注': "remark",
            '请选择服务状态': 'Please select service status',
            '请输入服务签约定金': 'Please enter the service contract deposit',
            '方案详情': 'Scheme details',
            '方案列表': 'Scheme list'


        },
        '用户认证管理': {
            '姓名': 'Name',
            '请输入姓名': 'Please enter your name',
            '状态': 'Status',
            '请选择状态': 'Please select status',
            '审核': 'Audit',
            '审核状态': 'Audit status',
            '电话': 'telephone',
            '邮箱': 'Email',
            '项目类别': 'Item category',
            '通过': 'pass',
            '驳回': 'Turn down',
            '驳回原因': 'Cause of rejection',
            '请输入驳回原因': 'Please enter the reason for rejection'
        },
        '用户授权管理': {
            '项目地址': 'Project address',
            '业主姓名': "Owner's name",
            '审核状态': 'Audit status',
            '请输入项目地址': 'Please enter the project address',
            '请输入业主姓名': "Please enter the owner's name",
            '土地面积': 'Land area',
            '请输入土地面积': 'Please enter the land area',
            '开发经验': "Development experience",
            '请输入开发经验': 'Please enter development experience',
            '身份': 'standing',
            '服务内容': 'Service content',
            '请输入服务内容': 'Please enter the service content',
            '签名': 'signature',
            '审核详情': 'Audit details',
            '通过': 'pass',
            '驳回': 'Turn down',
            '驳回原因': 'Cause of rejection',
            '请输入驳回原因': 'Please enter the reason for rejection'


        },

    },
    '用户信息': {
        '手机号': 'cell phone number',
        '邮箱': 'email',
        '性别': 'sex',
        '邀请码': 'Invitation code',
        '是否推送': 'Push or not',
        '用户状态': 'user mode',
        '请输入手机号': 'Please enter your phone number',
        '请输入邮箱': "Please enter email",
        '请输入昵称': 'Please enter a nickname',
        '请输入邀请码': 'Please enter the invitation code',
    },
    '用户注销': {
        '联系电话': 'Phone',
        '请输入联系电话': 'Please enter First Name!',
        '审核状态': 'audit status',
        '注销原因': 'Reason for cancellation',
        '请输入注销原因': 'Please enter the reason for logout',
        '审核失败原因': "Reasons for audit failure",
        '请输入审核失败原因': 'Please enter the reason for the audit failure',
        '审核人': 'auditor',
        '请输入审核人': 'Please enter the reviewer',
        '请输入联系电话': 'Please enter a contact number',
        '请选择审核状态': '请选择审核状态',
        '备注': "remark",
        '请输入备注': 'Please enter remarks'

    },
    '用户管理': {
        "请输入搜索内容": 'Please enter your search',
        '用户名称': 'username',
        "请输入用户名称": 'Please enter the user name',
        '手机号': "Phone number",
        "请输入手机号": 'Please enter your phone number',
        "创建时间": 'create time',
        '新增用户': "add new users",
        '修改用户': 'Modify user',
        '用户昵称': "User nickname",
        "请输入用户昵称": "Please enter a user nickname",
        '部门': "department",
        "请选择用户部门": "Please select user department",
        "邮箱": 'email',
        '请输入邮箱': 'Please enter email',
        '密码': "password",
        '请输入密码': 'enter your PIN',
        '性别': 'gender',
        '男': 'man',
        '女': 'woman',
        "未知": 'unknown',
        '状态': "state",
        "请选择状态": 'Please select status',
        '正常': 'normal',
        '停用': 'block up',
        '岗位': 'post',
        '请选择岗位': "Please select a position",
        '角色': 'role',
        '请选择角色': 'Please select a role',
        '备注': 'remark',
        '请输入备注': 'Please enter remarks',
        '重置密码': 'reset passwords',
        '分配角色': "assign roles",
        '新密码': 'new password',
        '请输入新密码': 'Please enter your new password',
        '确认密码': 'confirm password',
        '请确认密码': 'Please confirm the password',
        '的数据': 'Data of'

    },
    '角色管理': {
        '角色名称': 'character name',
        '请输入角色名称': 'Please enter a role name',
        '权限字符': 'Permission character',
        '请输入权限字符': 'Please enter permission character',
        '状态': 'status',
        '请选择状态': 'Please select status',
        '创建时间': 'create time',
        '脱敏权限': 'Desensitization authority',
        '数据权限': 'data permission',
        '分配用户': 'Assigned user',
        '添加角色': 'Add a role',
        '修改角色': 'Modify role',
        '角色名称': 'character name',
        "请输入角色名称": 'Please enter a role name',
        '权限字符': 'Permission character',
        '排序': 'sort',
        '请输入排序': 'Please enter sort',
        '备注': 'remark',
        '请输入备注': 'Please enter remarks',
        '菜单权限': 'Menu permissions',
        '列名': 'column name',
        '请输入列名': 'Please enter a column name',
        '列名描述': 'Column name description',
        '请输入列名描述': 'Please enter a column name description'

    },
    '系统管理': {
        '菜单管理': {
            '菜单名称': 'Menu name',
            '请输入菜单名称': 'Please enter a menu name',
            "状态": 'status',
            '请选择状态': 'Please select status',
            '添加菜单': 'Add menu',
            '修改菜单': 'Modify menu',
            '上级菜单': 'previous menu',
            '菜单类型': 'Menu type',
            '图标': 'icon',
            '排序': 'sort',
            '请输入排序': 'Please enter sort',
            '是否外链': 'Outside chain or not',
            '路由地址': 'Routing address',
            '请输入路由地址': 'Please enter the routing address',
            '是否显示': 'Show or not',
            '状态': 'state',
            '目录': 'catalogue',
            '菜单': 'menu',
            '按钮': "button",
            '组件路径': "Component path",
            '请输入组件路径': 'Please enter the component path',
            '是否缓存': 'Cache or not'

        },
        '脱敏管理': {
            '列名': 'column name',
            '请输入列名': 'Please enter a column name',
            '列名描述': 'Column name description',
            '请输入列名描述': 'Please enter a column name description',
            '修改脱敏数据': 'Modify desensitization data',
            '脱敏方向': 'Desensitization direction',
            '从左向右': 'From left to right',
            '从右向左': 'right-to-left',
            '起始位置': 'initial position',
            '请输入起始位置': 'Please enter the starting position',
            '脱敏字符数': 'Number of desensitized characters',
            '请输入脱敏字符数': 'Please enter the number of desensitized characters',
            '是否开启': 'Enable or not',
            '正常': 'normal',
            '停用': 'block up',
            '备注': 'remark',
            '请输入备注': 'Please enter remarks'
        },
        '部门管理': {
            '部门名称': 'industry title',
            '请输入部门名称': 'Please enter a department name',
            '状态': 'status',
            '请选择状态': 'Please select status',
            '启用': 'start using',
            '禁用': 'forbidden',
            '添加部门': 'Add department',
            '修改部门': 'Modify department',
            '上级部门': 'superior department',
            '请选择上级部门': 'Please select the upper department',
            '排序': 'sort',
            '请输入排序': 'Please enter sort',
            '负责人': 'principal',
            '请输入负责人': 'Please enter responsible person',
            '联系电话': 'phone',
            '请输入联系电话': 'Please enter a contact number',
            '邮箱': 'email',
            '请输入邮箱': 'Please enter email',


        },
        '岗位管理': {
            '岗位编码': 'Post code',
            '请输入岗位编码': 'Please enter the job code',
            '岗位名称': 'Please enter the job title',
            '添加岗位': 'Add a post',
            '修改岗位': 'Modify post',
            '岗位名称': 'name of the post',
            '请输入岗位名称': 'Please enter the job title',
            '岗位编码': 'Post code',
            '请输入岗位编码': 'Please enter the job code',
            '显示顺序': 'Display sequence',
            '请输入顺序': 'Please enter the order',
            '状态': 'state',
            '备注': 'remark',
            '请选择状态': 'Please select status',
            '请输入备注': 'Please enter remarks'
        },
        '字典管理': {
            '字典名称': "Dictionary name",
            '请输入字典名称': 'Please enter a dictionary name',
            '字典类型': 'Dictionary type',
            '请输入字典类型': 'Please enter the dictionary type',
            '状态': 'status',
            '请选择状态': 'Please select status',
            '添加字典类型': 'Add dictionary type',
            '修改字典类型': 'Modify dictionary type',
            '备注': 'remark',
            '请输入备注': 'Please enter remarks',
            '刷新缓存':'Refresh cache'
        },
        '参数设置':{
            '参数名称':'name of parameter',
            '请输入参数名称':'Please enter a parameter name',
            '参数键名':'Parameter key name',
            '请输入参数键名':'Please enter the parameter key name',
            '创建时间':'creation time',
            '系统内置':'System built-in',
            '请选择':'please choose',
            '参数分组':'Parameter grouping',
            '请输入参数分组':'Please enter parameter group',
            '参数类型':'parameter type',
            '参数键值':'Parameter key value',
            '请输入参数键值':'Please enter parameter key value',
            '备注':'remark',
            '请输入备注':'Please enter remarks',
            '添加参数':'addparameter',
            '修改参数':'modify parameter'
        },
        '通知公告':{
            '公告标题':'Announcement title',
            '请输入公告标题':'Please enter the announcement title',
            '操作人员':'operating personnel',
            '请输入操作人员':'Please enter operator',
            '公告类型':'Announcement type',
            '请选择公告类型':'Please select a bulletin type'
        },
        '日志管理':{
            '操作日志':{
                   '系统模块':'system module' ,
                   '请输入系统模块':'Please enter the system module',
                   '操作人员':'operating personnel',
                   '请输入操作人员':'Please enter operator',
                   '类型':'type',
                   '操作类型':'operation type',
                   '状态':'state',
                   '操作状态':'operation status',
                   '操作时间':'operation time',
                   '操作信息':'operating information',
                   '操作模块':'operation module',
                   '登录信息':'login information',
                   '请求地址':'Request address',
                   '操作方法':'operational approach',
                   '请求方式':'way to request',
                   '接口信息':'interface message',
                   '请求参数':"required parameter",
                   '返回参数':'return parameters',
                   '操作状态':'operation status',
                   '操作日期':'Date of operation',
                   '异常信息':'abnormal information'
            },
            '登录日志':{
                '登录地址':'Login address',
                '请输入登录地址':'Please enter your login address',
                '使用状态':'use of state',
                '请选择状态':'Please select status',
                '登录时间':'login time',
                '登录名称':'login name',
                '请输入登录名称':'Please enter your login name'
          }
      },
      'DIY项目管理':{
        '项目名称':'project name',
        '请输入项目名称':'Please enter a project name',
        '联系人姓名':'name of contact person',
        '请输入联系人姓名':'Please enter the contact name',
        '添加DIY项目信息':'Add DIY project information',
        '修改DIY项目信息':'Modify DIY project information',
        '项目名称':'project name',
        '请输入项目名称':'Please enter a project name',
        'DIY模块项目ID':'DIY module project ID',
        '联系人电话':'phone number of the contact',
        '请输入联系人电话':'Please enter the contact number',
        '数据库IP':'Database IP',
        '请输入数据库IP':'Enter the database IP address',
        '数据库端口':'Database port',
        '请输入数据库端口':'Please enter the database port',
        '数据库名称':'name of the database',
        '请输入数据库名称':'Please enter a database name',
        '数据库用户名':'Database user name',
        '请输入数据库用户名':'Please enter the database user name',
        '数据库密码':'Database password',
        '请输入数据库密码':"Please enter the database password"

   }
    },
    '经纪人/服务者':{
          '经纪人':{
                '代理人姓名':"Agent's name",
                '代理人所在公司':"Agent's company",
                '代理人电话':'Agent telephone',
                '业主姓名':"Owner's name",
                '电话号码':'phone number',
                '头像':'head portrait',
                '所在公司年份':'Company year',
                '代理人邮件':'Agent mail',
                '推荐项目地址':'Recommended project address',
                '电话号码':'phone number',
                '城市':'city',
                '邮政编码':'postal code',
                '邀请码':'Invitation code',
                '审核状态':'audit status',
                '未审核':'unreviewed',
                '已通过':'already passed',
                '可以试看':'May try',
                '审核驳回的情况下的拒绝原因':'Review reasons for rejection in case of rejection',
                '审核':'audit',
                '通过':'pass',
                '拒绝':'turn down',
                '请输入代理人姓名':'Please enter your agent name',
                '请输入代理人所在公司':"Please enter the agent's company",
                '请输入代理人电话':"Please enter your agent's phone number",
                '请输入业主姓名':"Please enter the owner's name",
                '请输入电话号码':'Please enter the phone number',
          }  ,
          '服务者':{
            '总经理姓名':'Name of general manager',
            '请输入总经理姓名':'Please enter the general manager name',
            '公司名称':'company name',
            '请输入公司名称':'Please enter the company name',
            '电话号码':'phone number',
            '请输入电话号码':'Please enter the phone number',
            '详情':'details',
            '公司地址':'company address',
            '电子邮件':'electronic mail',
            '专业身份':'Professional identity',
            '其他':'other',
            '总工龄':"Total seniority",
            '公司人数':'number of employee',
            '公司在银行信用额度':'The company has a line of credit at the bank',
            '是否推荐':'Whether to recommend',
            '是':'yes',
            '否':'no',
            '是否开启服务':'Whether to enable the service',
            '服务金额':'Amount of service',
            '评分':'score',
            '擅长':'be good at',
            '详细介绍':'detailed introduction',
            '备注':'remark',
            '审核':'audit',
            '通过':'pass',
            '拒绝':'turn down',
            '审核拒绝原因':'Reasons for audit rejection',

         },
         '身份设置':{
            '身份':'identity',
            '请输入身份':'Please enter your identity',
            '上级身份,顶级不选':'Superior status, not top level',
            '请输入':'please enter',
            '图片':'picture',
            '备注':'remark',
            '请输入备注':'Please enter remarks'
      }
    }
}
